var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t('admin.words.add'))+" ")],1)],1)]),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('admin.words.title'))+" "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":!_vm.search ? 'mdi-magnify' : undefined,"clearable":"","label":_vm.$t('common.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.dialog)?_c('word-create',{attrs:{"dialog":_vm.dialog,"item":_vm.itemSelected},on:{"update:dialog":function($event){_vm.dialog = $event},"update:table":_vm.fetchWords}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [5, 10, 30, 50, -1] },"items":_vm.items,"sort-by":_vm.sortby,"multi-sort":true,"search":_vm.search,"custom-filter":_vm.filterTableItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.name",fn:function(ref){
var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.attributes.group",fn:function(ref){
var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(_vm.getType(value),_vm.search)}})]}},{key:"item.attributes.created-at",fn:function(ref){
var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(_vm.dateHelper.getDateTimeTableFormat(value),_vm.search)}})]}},{key:"item.attributes.updated-at",fn:function(ref){
var value = ref.value;
return [_c('div',{domProps:{"innerHTML":_vm._f("highlight")(_vm.dateHelper.getDateTimeTableFormat(value),_vm.search)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fetchWords}},[_vm._v(" "+_vm._s(_vm.$t('admin.common.resetData'))+" ")])]},proxy:true}])})],1),_c('confirm-dialog',{ref:"confirm"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var words = [
    { name: 'verbsFirstPersons', value: 1 },
    { name: 'substantives', value: 2 },
    { name: 'adjectives', value: 3 },
    { name: 'verbsGerund', value: 4 },
    { name: 'substantives2', value: 5 },
    { name: 'ourBusiness', value: 6 }

];

export default words;

<template>
    <div>
        <words-component />
    </div>
</template>

<script>
import WordsComponent from '@/components/admin/words/wordsList'
export default {
    name: "AdminWords",
    components: { WordsComponent },
    data: () => ({

    })
};
</script>

<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="create"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.words.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.words.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <word-create
                    v-if="dialog"
                    :dialog="dialog"
                    :item="itemSelected"
                    @update:dialog="dialog = $event"
                    @update:table="fetchWords"
                />
                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.attributes.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.attributes.group="{ value }">
                        <div :inner-html.prop="getType(value) | highlight(search)" />
                    </template>

                    <template v-slot:item.attributes.created-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>
                    <template v-slot:item.attributes.updated-at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchWords"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import WordCreate from '@/components/admin/words/wordsCreate'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";

// import * as dateHelper from '@/helpers/date'
import wordsListKey from '@/components/admin/words/wordsListKey'
export default {
    components: { WordCreate, ConfirmDialog },
    data: function () {
        return {
            urlMain: '/words',
            dialog: false,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.words.name'),
                    value: 'attributes.name'
                },
                {
                    text: this.$t('admin.words.type'),
                    value: 'attributes.group'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'attributes.created-at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'attributes.updated-at'
                },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['id'],
            /* pagination: {
                totalItems: 10
            }, */
            options: {
                itemsPerPage: 10
            }
        }
    },
    watch: {
        /* options: {
            handler(newValue, oldValue) {
                this.fetchWords(newValue)

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'))
                }
            }
        },
        search: {
            handler(newValue) {
                this.fetchWords();
            }
        }, */

        dialog(val) {
            val || this.close()
        }
    },
    created() {
        this.fetchWords();
    },

    mounted() {

    },

    methods: {
        fetchWords: debounce(function (e) {
            this.fetchWordsFinal(e);
        }, 500),

        async fetchWordsFinal(data = this.options) {
            try {
                const words = await axios
                    .get(this.urlMain)

                this.initialize(words.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.data

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0
        },
        create() {
            this.dialog = true;
        },

        editItem(item) {
            this.itemSelected = item;
            this.dialog = true;
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 204) {
                    this.fetchWords();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        getType(value) {
            const wordKey = wordsListKey.find(w => w.value === value);
            const msg = this.$t("purposes.textEditor.words." + wordKey.name);
            return `${msg.up} ${msg.down}`;
        },
        filterTableItems(value, search, item) {
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (this.dateHelper.isADateTime(value)) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    filter = this.getType(value).toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1;
                }
            }

            return filter;
        }
    }
}
</script>

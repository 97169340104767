<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"

        persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <!-- Edited users-->
            <v-card-text>
                <v-form
                    v-model="isValid"
                    class="mb-16"
                >
                    <v-container class="pt-0 pb-6">
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-select
                                    v-model="editedItem.group.value"
                                    :error-messages="editedItem.group.error"
                                    :items="parentsOptions"
                                    attach
                                    :label="$t('admin.words.type')"
                                    item-text="name"
                                    item-value="id"
                                    :rules="[rules.requiredAtLeastOne]"
                                    @change="editedItem.group.error = ''"
                                />

                            <!--                                <v-text-field
                                v-model="editedItem.group.value"
                                class="purple-input"
                                :label="$t('admin.words.type')"
                                :error-messages="editedItem.group.error"
                                :rules="[rules.required]"
                                @keyup="editedItem.group.error = ''"
                            />-->
                            </v-col>

                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    v-model="editedItem.name.value"
                                    class="purple-input"
                                    :label="$t('admin.words.name')"
                                    :error-messages="editedItem.name.error"
                                    :rules="[rules.required]"
                                    @keyup="editedItem.name.error = ''"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions class="mt-16">
                <v-spacer />
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                    {{ $t('common.confirmDialogCancel') }}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!isValid && !serverCheck"
                    @click="save"
                >
                    {{ $t('common.dialogSave') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/plugins/axios'
// import { mapGetters } from 'vuex'
import rules from '@/helpers/rulesValidate'

import wordsListKey from '@/components/admin/words/wordsListKey'

export default {
    props: {
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
        item: {
            type: Object,
            required: false
        }
    },
    data: function () {
        return {
            urlMain: '/words',
            rules: {
                ...rules
            },
            editedItem: this.getEditedItem(),
            isValid: true,
            serverCheck: false,
            parentsOptions: this.getWordsList()
        }
    },
    computed: {
        formTitle() {
            return this.item
                ? this.$t('admin.words.edit')
                : this.$t('admin.words.new')
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                this.editedItem = this.getEditedItem()
            }
        }
    },

    methods: {
        getEditedItem() {
            const item = this.item
            /* console.log('******* item **********')
        console.log(item) */

            var empty = !item

            return {
                id: empty ? '' : item.id,
                name: {
                    value: empty ? '' : item.attributes.name,
                    error: ''
                },
                group: {
                    value: empty ? '' : item.attributes.group,
                    error: ''
                }
            }
        },
        close() {
            this.$emit('update:dialog', false)
        },

        async save() {
            var dataSubmit = {
                data: {
                    type: 'words',
                    attributes: {
                        name: this.editedItem.name.value,
                        group: this.editedItem.group.value
                    }
                }
            }

            let urlAppend = ''
            let method = 'post'
            if (this.editedItem.id) {
                urlAppend = '/' + this.editedItem.id
                dataSubmit.data.id = '' + this.editedItem.id

                method = 'patch'
            }

            try {
                const result = await axios({
                    method: method,
                    url: this.urlMain + urlAppend,
                    data: dataSubmit
                })

                if (result.status === 200 || result.status === 201 || result.status === 204) {
                    this.close()

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                }
            } catch (error) {
                if (error.response.status === 422) {
                    /* console.log('********** errors ********')

            console.log(error.response.data.errors) */

                    if (error.response?.data?.errors?.length) {
                        error.response.data.errors.forEach((e) => {
                            if (e.source.pointer === '/data/attributes/name') {
                                this.editedItem.name.error = e.detail
                            }
                            if (e.source.pointer === '/data/attributes/group') {
                                this.editedItem.group.error = e.detail
                            }
                        })
                    }
                }

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$emit('update:table')
        },
        getWordsList() {
            return wordsListKey.map(wordKey => {
                const msg = this.$t("purposes.textEditor.words." + wordKey.name);
                return { id: wordKey.value, name: `${msg.up} ${msg.down}` }
            });
        }
    }
}
</script>
